<template>
    <div class="container-n-v-i">
        <div :class="'titulo-u-noticias'+' '+isNav()">
            <h4>ÚLTIMAS <span>IMÁGENES</span></h4>
            <Sponsor2 :secSponsor2="'IMAGENES'" class="patroc"/>
        </div>

        <div class="last-cont">

    <GalleryModal v-show="isModalVisible7" @close="closeModal7">
      <template v-slot:header>
        GALERÍA
      </template>

      <template v-slot:body> 
        <div class="gallery-item" v-for="(imadata,i) in modalData7" :key="imadata.id">
          <img class="gallery-frame" :src="url+'/get-imagesgallery-image/'+ isWebp(regexImg(imadata.image), imadata.image)" alt="image" @click="showModal8(imadata,i)"/>
        </div> 
      </template>
    </GalleryModal>

    <GalleryImgModal v-show="isModalVisible8" @close="closeModal8">
      <template v-slot:body> 
        <div class="cont-img-gal">
          <div class="btn-content" @click="closeModal8">
            <a class="prev" id="pr" @click="plusSlides(modalData7,-1)" @click.stop="">❮</a>
          </div>
          <img class="gallery-frame-img" :src="url+'/get-imagesgallery-image/'+ isWebp(regexImg(modalData8.image), modalData8.image)" alt="image"/>
          <div class="btn-content" @click="closeModal8">
          <a class="next" id="nx" @click="plusSlides(modalData7,1)" @click.stop="">❯</a>
          </div>
          </div>
        <div class="gallery-mosaic">
          <img v-for="(md,i) in modalData7" :key="md.id"
           :src="url+'/get-imagesgallery-image/'+md.image" alt="img" class="rest-of-pics" @click="showModal8(md,i)">
        </div>
      </template>
    </GalleryImgModal>

            <div class="l-cont" v-for="(gale,key) in gallerydata4.slice(0,4)" :key="key" @click="showModal7(gale.imagenes)">
                <div class="l-div">
                        <div :style="{ backgroundImage: 'url(' + url+'/get-imagesgallery-image/'+isWebp(regexImg(gale.image), gale.image)  + ')'}" width="377" height="350" alt="e" class="l-m-content"/>
                        <img :src="isWebp(require('../assets/webp/red-rec.webp'), require('../assets/red-rec.png'))" width="118" height="12" alt="rr" class="red-rec">

                </div>
                <div class="titulo-vim">
                    <p :class="'date'+' '+isNav()">
                    <!--  HACE {{ newsdateago(gale.dateimg) }}</p> -->
                       {{ dataSortDue(gale.dateimg) }}</p>
                    <div>
                        <h4 class="titulo">{{gale.title}}</h4>
                    </div>
                </div>
            </div>

        </div>

        <div class="lastb">
            <a href="/galeria">
                <div :class="'l-button'+' '+isNav()">
                    VER TODO
                </div>
            </a>
        </div>

    </div>
</template>

<script>
import Sponsor2 from "../components/widgets/SponsorSecW2";
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import GalleryModal from "../components/GalleryModal.vue";
import GalleryImgModal from "../components/GalleryImgModal.vue";

export default {
  components: {
    Sponsor2,GalleryModal,GalleryImgModal
  },
  data() {
    
    return {
      isModalVisible7: false,
      modalData7: null,
      isModalVisible8: false,
      modalData8: null,
      indexModal:0,
    }
  },
  created() {
    this.getGallerys3();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    gallerydata4() {
      let gallery = this.$store.getters["gallery/data5"];
      let elekipo = gallery.sort(
        (a, b) => {return new Date(b.dateimg) - new Date(a.dateimg);}
      );
      return elekipo;
    },
  },
  methods: {
    ...mapActions("gallery", ["getGallerys3"]),

        regexImg(txt){
          let s = txt.split('.')
          return s[0]+'.webp'

        },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return webpImg; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return webpImg; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return webpImg; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return pngImg; // Safari
            }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return 'webp'; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return 'webp'; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return 'webp'; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return ''; // Safari
            }
    },

    showModal7(data) {
      this.isModalVisible7 = true;
      this.modalData7 = data;
    },
    closeModal7() {
      this.isModalVisible7 = false;
      this.modalData7 = null;
    },

    showModal8(data, i) {
      this.isModalVisible8 = true;
      this.indexModal = i;
      this.modalData8 = data;
    },
    closeModal8() {
      this.isModalVisible8 = false;
      this.modalData8 = null;
    },

    plusSlides(data, n) {
      this.isModalVisible8 = true;
      let clairo = this.indexModal += n;
     /* console.log('cheems '+data.length)*/
      if(clairo < 0){
        document.getElementById('pr').style.pointerEvents = "none"
        document.getElementById('pr').style.opacity = ".5"
      }else if (clairo > data.length){
        document.getElementById('nx').style.pointerEvents = "none"
        document.getElementById('nx').style.opacity = ".5"
      }else{
      document.getElementById('pr').style.pointerEvents = "auto"
      document.getElementById('nx').style.pointerEvents = "auto"
        document.getElementById('pr').style.opacity = "1"
        document.getElementById('nx').style.opacity = "1"
      this.modalData8 = data[clairo];
      }
    },

    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },
    dataSortDue: function (date) {
     const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let newsdata = dayjs(date).locale(es).format("YYYY-MMMM-DD");
     
     let fecha = newsdata.split("-");
      let yy = fecha[0];
      let mm = fecha[1];
      let dd = fecha[2];
      return (fecha = dd  + " DE " + mm + " DEL " + yy);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

    getGallerys3: async function () {
      let result = await this.$store.dispatch("gallery/getGallerys3", {
        option: "galeria",
      });
      this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
};
</script>

<style scoped>
.l-m-content {
    background-size: 140%!important;
    background-position-x: center;
    background-position-y: center;
    transition: .5s;
}
.l-cont:hover .l-m-content {
    background-size: 180%!important;
}.patroc {
    display: none!important;
}
</style>
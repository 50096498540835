<template>
  <div id="home">
    <div id="headerHome" class="col-sm-12 col-md-12  color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>
    <div id="SliderHome">
      <SliderHome />
    </div>
    
    <div id="ultimasNoticias">
      <UltimasNoticias />
    </div>

    <div id="partidos" :class="isNav()">
      <div class="header-partidos">
        <h1 class="titulo-partidos">
          PARTIDOS
        </h1>
        <div class="calendar-cont">
          <a href="/calendarios">
            VER CALENDARIO
          </a>
        </div>
      </div>
      <div class="slide-container-partidos">
        <SliderPartidos />
      </div>
    </div>

    <div id="sponsor" class="tkt">
      <Sponsor :patrocinador="true" />
    </div>

    <div id="storeSection" :class="isNav()">
      <Tienda />
    </div>

    <div id="clasificacion">
      <Clasificacion />
    </div>

    <div id="sponsor">
      <Sponsor :patrocinador="false" />
    </div>

    <div id="ultimosVideos">
      <UltimosVideos />
    </div>

    <div id="ultimasImagenes">
      <UltimasImagenes />
    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import SliderHome from "../components/sliderHome";
import SliderPartidos from "../components/SlidePartidos";
import Sponsor from "../components/Sponsor";
import UltimasNoticias from "../components/UltimasNoticias";
import Clasificacion from "../components/Clasificacion";
import UltimosVideos from "../components/UltimosVideos";
import UltimasImagenes from "../components/UltimasImagenes";
import Tienda from "../components/StoreSection";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Home",
  components: {
    HeaderHome,
    SliderHome,
    SliderPartidos,
    Sponsor,
    UltimasNoticias,
    Clasificacion,
    UltimosVideos,
    UltimasImagenes,
    Tienda,
    Footer,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods:{
    
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return webpImg; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return webpImg; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return webpImg; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return pngImg; // Safari
            }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return 'webp'; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return 'webp'; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return 'webp'; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return ''; // Safari
            }
    },
  }
};
</script>

<style scoped>
div#sponsor.tkt {
    display: none;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VueperSlides',{staticClass:"sl-partidos-pc",attrs:{"arrows":true,"dragging-distance":100,"touchable":false}},_vm._l((_vm.jornadaProx),function(slide,i){return _c('VueperSlide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [(slide.slide_tournament == 'ACTIVO')?_c('div',{staticClass:"master-slide"},[(slide.descrp < _vm.today())?_c('h1',{staticClass:"prev-last-next-match"},[_c('a',{attrs:{"href":'/calendario/' + slide.id}},[_c('div',{class:'l-button' + ' ' + _vm.isNav()},[_vm._v("VER RESULTADOS")])])]):(slide.descrp >= _vm.today())?_c('h1',{staticClass:"prev-last-next-match"},[_c('a',{attrs:{"href":_vm.boletos(slide.descrp),"target":"_blank","rel":"noopener noreferrer"}},[_c('div',{class:'l-button' + ' ' + _vm.isNav()},[_vm._v("COMPRAR BOLETOS")])])]):_vm._e(),(
              slide.descrp >= _vm.today() && slide.slide_tournament == 'ACTIVO'
            )?_c('div',{staticClass:"slide-calendar versa diag",style:('background-color:' + slide.color1)},[_c('div',{staticClass:"fecha-cont"},[_c('h1',{staticClass:"fecha",style:('color:' + slide.color3)},[_vm._v(" "+_vm._s(_vm.matchday(_vm.dataTransform(slide.descrp)))+" ")]),_c('h2',{staticClass:"mes"},[_vm._v(" "+_vm._s(_vm.matchmonth(_vm.dataTransform(slide.descrp)))+" ")]),_c('h3',{staticClass:"hora",style:('background-color:' + slide.color2)},[_vm._v(" "+_vm._s(slide.hr +' HRS')+" ")])]),_c('div',{staticClass:"partido-cont"},[_c('div',{staticClass:"jornada-content"},[_c('div',{staticClass:"journey-lmx"},[_c('h3',{staticClass:"tipo-juego",style:('color:' + slide.color4)},[_vm._v(" "+_vm._s(slide.typegame)+" ")]),_c('h4',{staticClass:"estadio-name"},[_vm._v("Estadio: "+_vm._s(slide.stadium))])]),(slide.channels == 'No se encontraron coincidencias')?_c('div',{staticClass:"canales"}):_c('div',{staticClass:"canales"},[_c('picture',[_c('img',{staticClass:"channel",attrs:{"src":_vm.url +
                        '/get-channel-image/' + slide.channels[0].image_channel,"alt":"canal","width":"82","height":"51"}})])])]),(slide.stadium == 'Héroe de Nacozari')?_c('div',{staticClass:"loc"},[(slide.descrp >= _vm.today())?_c('div',{staticClass:"partido-res"},[_c('div',{staticClass:"local-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("../assets/Cimarrones-mx.webp")}}),_c('source',{attrs:{"srcset":require("../assets/Cimarrones-mx.png"),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":require("../assets/Cimarrones-mx.webp"),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v("cimarrones de sonora")])])]),_c('h1',{staticClass:"versus-h1",style:('color:' +
                      slide.color5 +
                      ', border-color: ' +
                      slide.color5)},[_vm._v(" VS ")]),_c('div',{staticClass:"visita-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image)}}),_c('source',{attrs:{"srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v(_vm._s(slide.opname))])])])]):(slide.descrp <= _vm.today())?_c('div',{staticClass:"partido-res"},[_c('div',{staticClass:"local-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("../assets/Cimarrones-mx.webp")}}),_c('source',{attrs:{"srcset":require("../assets/Cimarrones-mx.png"),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":require("../assets/Cimarrones-mx.webp"),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v("CIMARRONES DE SONORA")])])]),_c('h1',{staticClass:"versus-h1",style:('color:' +
                      slide.color5 +
                      ', border-color: ' +
                      slide.color5)},[_vm._v(" VS ")]),_c('div',{staticClass:"visita-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image)}}),_c('source',{attrs:{"srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v(_vm._s(slide.opname))])]),_c('p',{staticClass:"resultado"},[_vm._v(_vm._s(slide.result_op))])])]):_vm._e()]):_c('div',{staticClass:"vis"},[(slide.descrp >= _vm.today())?_c('div',{staticClass:"partido-res"},[_c('div',{staticClass:"local-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("../assets/Cimarrones-mx.webp")}}),_c('source',{attrs:{"srcset":require("../assets/Cimarrones-mx.png"),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":require("../assets/Cimarrones-mx.webp"),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v("Cimarrones de Sonora")])])]),_c('h1',{staticClass:"versus-h1",style:('color:' +
                      slide.color5 +
                      ', border-color: ' +
                      slide.color5)},[_vm._v(" VS ")]),_c('div',{staticClass:"visita-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image)}}),_c('source',{attrs:{"srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v(_vm._s(slide.opname))])])])]):(slide.descrp <= _vm.today())?_c('div',{staticClass:"partido-res"},[_c('div',{staticClass:"local-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("../assets/Cimarrones-mx.webp")}}),_c('source',{attrs:{"srcset":require("../assets/Cimarrones-mx.png"),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":require("../assets/Cimarrones-mx.webp"),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v("CIMARRONES DE SONORA")])])]),_c('h1',{staticClass:"versus-h1",style:('color:' +
                      slide.color5 +
                      ', border-color: ' +
                      slide.color5)},[_vm._v(" VS ")]),_c('div',{staticClass:"visita-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image)}}),_c('source',{attrs:{"srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v(_vm._s(slide.opname))])])])]):_vm._e()])])]):(
              slide.descrp <= _vm.today() && slide.slide_tournament == 'ACTIVO'
            )?_c('div',{staticClass:"slide-calendar diag",style:('background-color:' + slide.color1)},[_c('div',{staticClass:"fecha-cont"},[_c('h1',{staticClass:"fecha",style:('color:' + slide.color3)},[_vm._v(" "+_vm._s(_vm.matchday(_vm.dataTransform(slide.descrp)))+" ")]),_c('h2',{staticClass:"mes"},[_vm._v(" "+_vm._s(_vm.matchmonth(_vm.dataTransform(slide.descrp)))+" ")]),_c('h3',{staticClass:"hora",style:('background-color:' + slide.color2)},[_vm._v(" "+_vm._s(slide.hr +' HRS'/**slide.hr **/)+" ")])]),_c('div',{staticClass:"partido-cont"},[_c('div',{staticClass:"jornada-content"},[_c('div',{staticClass:"journey-lmx"},[_c('h3',{staticClass:"tipo-juego",style:('color:' + slide.color4)},[_vm._v(" "+_vm._s(slide.typegame)+" ")]),_c('h4',{staticClass:"estadio-name"},[_vm._v("Estadio: "+_vm._s(slide.stadium))])]),_c('div',{staticClass:"canales"},[_c('h1')])]),(slide.stadium == 'Héroe de Nacozari')?_c('div',{staticClass:"loc"},[(slide.descrp >= _vm.today())?_c('div',{staticClass:"partido-res"},[_c('div',{staticClass:"local-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("../assets/Cimarrones-mx.webp")}}),_c('source',{attrs:{"srcset":require("../assets/Cimarrones-mx.png"),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":require("../assets/Cimarrones-mx.webp"),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v("cimarrones de sonoRa")])])]),_c('h1',{staticClass:"versus-h1",style:('color:' +
                      slide.color5 +
                      ', border-color: ' +
                      slide.color5)},[_vm._v(" VS ")]),_c('div',{staticClass:"visita-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image)}}),_c('source',{attrs:{"srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v(_vm._s(slide.opname))])])])]):(slide.descrp <= _vm.today())?_c('div',{staticClass:"partido-res"},[_c('div',{staticClass:"local-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("../assets/Cimarrones-mx.webp")}}),_c('source',{attrs:{"srcset":require("../assets/Cimarrones-mx.png"),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":require("../assets/Cimarrones-mx.webp"),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v("CIMARRONES DE SONORA")])]),_c('p',{staticClass:"resultado"},[_vm._v(" "+_vm._s(slide.result_c)+" ")])]),_c('h1',{staticClass:"versus-h1",style:('color:' +
                      slide.color5 +
                      ', border-color: ' +
                      slide.color5)},[_vm._v(" VS ")]),_c('div',{staticClass:"visita-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image)}}),_c('source',{attrs:{"srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v(_vm._s(slide.opname))])]),_c('p',{staticClass:"resultado"},[_vm._v(" "+_vm._s(slide.result_op)+" ")])])]):_vm._e()]):_c('div',{staticClass:"vis"},[(slide.descrp >= _vm.today())?_c('div',{staticClass:"partido-res"},[_c('div',{staticClass:"local-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("../assets/Cimarrones-mx.webp")}}),_c('source',{attrs:{"srcset":require("../assets/Cimarrones-mx.png"),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":require("../assets/Cimarrones-mx.webp"),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v("CIMARRONES DE SONORA")])])]),_c('h1',{staticClass:"versus-h1",style:('color:' +
                      slide.color5 +
                      ', border-color: ' +
                      slide.color5)},[_vm._v(" VS ")]),_c('div',{staticClass:"visita-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image)}}),_c('source',{attrs:{"srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v(_vm._s(slide.opname))])])])]):(slide.descrp <= _vm.today())?_c('div',{staticClass:"partido-res"},[_c('div',{staticClass:"local-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("../assets/Cimarrones-mx.webp")}}),_c('source',{attrs:{"srcset":require("../assets/Cimarrones-mx.png"),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":require("../assets/Cimarrones-mx.webp"),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v("CIMARRONES DE SONORA")])]),_c('p',{staticClass:"resultado"},[_vm._v(" "+_vm._s(slide.result_c)+" ")])]),_c('h1',{staticClass:"versus-h1",style:('color:' +
                      slide.color5 +
                      ', border-color: ' +
                      slide.color5)},[_vm._v(" VS ")]),_c('div',{staticClass:"visita-cont"},[_c('div',{staticClass:"team-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image)}}),_c('source',{attrs:{"srcset":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"type":"image/png"}}),_c('img',{staticClass:"logo-equipo",attrs:{"width":"65","height":"72","src":_vm.url +
                            '/get-calendar-image/' +
                            _vm.regexImg(slide.image),"alt":""}})]),_c('p',{staticClass:"nombre-equipo"},[_vm._v(_vm._s(slide.opname))])]),_c('p',{staticClass:"resultado"},[_vm._v(" "+_vm._s(slide.result_op)+" ")])])]):_vm._e()])])]):_vm._e()]):_vm._e()]},proxy:true}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
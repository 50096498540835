<template>
  <div>
    <div class="store-sec-cont">
      <div class="ssc-titulo">
        <h2>BIENVENIDO A NUESTRA</h2>
        <h3>TIENDA OFICIAL</h3>
      </div>
      <div class="scc-slide pc">
        <vueper-slides
          class="no-shadow"
          :visible-slides="3"
          slide-multiple
          :bullets="false"
          :touchable="false"
          :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
        >
          <vueper-slide v-for="(slideShop, i) in productdata" :key="i"
         >
            <template #content>
              <div class="store-item">
                <a :href="'/producto/' + slideShop.url">
                  <div class="item-store-cont">
                    <picture>
                      <source
                        type="image/webp"
                        :srcset="
                          url +
                          '/get-product-image/' +
                          regexImg(slideShop.image)
                        "
                      />
                      <source
                        :srcset="url + '/get-product-image/' + slideShop.image"
                        type="image/png"
                      />
                      <img
                        :src="
                          url +
                          '/get-product-image/' +
                          regexImg(slideShop.image)
                        "
                        alt="store"
                        width="355"
                        height="379"
                      />
                    </picture>
                  </div>
                </a>
                <div class="ti-pr-cont">
                  <div
                    class="cat-genre"
                    v-if="genreName(slideShop.categorias) !== 'No se encontraron coincidencias'"
                  >
                    CATEGORIA:
                    <a :href="genreWeb(slideShop.categorias)">{{
                      genreName(slideShop.categorias)
                    }}</a>
                  </div>
                  <div
                    class="cat-genre"
                    v-else
                  >
                    CATEGORIA:
                    <a :href="'/categoria/' + tolc(slideShop.category_name)">{{
                      slideShop.category_name
                    }}</a>
                  </div>
                  <div class="ti-cont">
                    <p>{{ slideShop.name }}</p>
                    <!--  <h6>{{slideShop.size_name}}</h6> arkano -->
                  </div>
                  <div class="pr-cont">
                    $ {{ slideShop.price }} <span class="light">MXN</span>
                  </div>
                </div>
                <div>
                  <a class="comprar-btn" :href="'/producto/' + slideShop.url">
                    COMPRAR
                  </a>
                </div>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="scc-slide mo">
        <vueper-slides
          class="no-shadow"
          touchable
          :visible-slides="1"
          :bullets="false"
        >
          <vueper-slide v-for="(slideShop,i) in productdata" 
          :key="i"
          >
            <template #content>
              <div class="store-item">
                <a :href="'/producto/' + slideShop.url">
                  <div class="item-store-cont">
                    <picture>
                      <source
                        type="image/webp"
                        :srcset="
                          url +
                          '/get-product-image/' +
                          regexImg(slideShop.image)
                        "
                      />
                      <source
                        :srcset="url + '/get-product-image/' + slideShop.image"
                        type="image/png"
                      />
                      <img
                        :src="
                          url +
                          '/get-product-image/' +
                          regexImg(slideShop.image)
                        "
                        alt="store"
                        width="214"
                        height="228"
                      />
                    </picture>
                  </div>
                </a>
                <div class="ti-pr-cont">
                  <div
                    class="cat-genre"
                    v-if="genreName(slideShop.categorias) !== 'No se encontraron coincidencias'"
                  >
                    CATEGORIA:
                    <a :href="genreWeb(slideShop.categorias)">{{
                      genreName(slideShop.categorias)
                    }}</a>
                  </div>
                  <div
                    class="cat-genre"
                    v-else
                  >
                    CATEGORIA:
                    <a :href="'/' + slideShop.category_name">{{
                      slideShop.category_name
                    }}</a>
                  </div>
                  <div class="ti-cont">
                    <p>{{ slideShop.name }}</p>
                    <!--  <h6>{{slideShop.size_name}}</h6> -->
                  </div>
                  <div class="pr-cont">$ {{ slideShop.price }} MXN</div>
                </div>
                <a class="comprar-btn" :href="'/producto/' + slideShop.url">
                  COMPRAR
                </a>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>

      <div class="btn-ver-tienda-cont">
        <a href="/tienda">
          <p class="btn-ver-tienda">VER TODA LA TIENDA</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";

export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {};
  },
  created() {
    this.findAll();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    productdata() {
      /*let encontrado = false;
      let v;
      let productos = this.$store.getters['product/data'];
      productos.splice(productos.findIndex(v => v.status == "INACTIVO"), 1);
      return productos;*/
      let productos = this.$store.getters["product/data"];
      let newdata = [];
      let finaldata = []
      for (let i = 0; i < productos.length; i++) {
        let prod = productos[i];
        for (let j = 0; j < prod.categorias.length; j++) {
          if (prod.categorias[j].category_name == 'SLIDE TIENDA') {
            newdata.push(prod);
          }
        }
        /*if (newdata[i].status == "ACTIVO") {
          finaldata.push(newdata[i]);
        }*/
      }
            for (let k = 0; k < newdata.length; k++) {
              if(newdata[k].status == "ACTIVO"){
                  finaldata.push(newdata[k]);
              }
            }/**/
      return finaldata;
    },
  },
  methods: {
    ...mapActions("product", ["findAll"]),
    ...mapActions("product", ["findById"]),
    ...mapActions("product", ["getImageFile"]),

    slideTiendaCont(data) {
      return data.categorias;
    },
    regexImg(txt) {
      let s = txt.split(".");
      return s[0] + ".webp";
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return "webp"; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return "webp"; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return "webp"; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return ""; // Safari
      }
    },

    genreName(genre) {
      let name = "No se encontraron coincidencias";
      for (let i = 0; i < genre.length; i++) {
        if (genre[i].category_name == "MUJER") {
          name = "MUJER";
        } if (genre[i].category_name == "HOMBRE") {
          name = "HOMBRE";
        } if (genre[i].category_name == "NIÑO") {
          name = "NIÑO";
        }
      }
      return name;
    },

    genreWeb(genre) {
      let name = "No se encontraron coincidencias";
      for (let i = 0; i < genre.length; i++) {
        if (genre[i].category_name == "MUJER") {
          name = "/categoria/mujer";
        } if (genre[i].category_name == "HOMBRE") {
          name = "/categoria/hombre";
        } if (genre[i].category_name == "NIÑO") {
          name = "/categoria/niño";
        }
      }
      return name;
    },

    tolc(palabra) {
      let word = palabra.replaceAll(" ", "-");
      let newword = word
        .normalize("NFD")
        .replace(
          /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
          "$1"
        )
        .normalize();
      return newword.toLowerCase();
    },

    findAll: async function () {
      let result = await this.$store.dispatch("product/findAll", {
        option: "tienda",
      });
      this.info = result;
      return result;
    },
    productDetail: function (name) {
      return (name = name.replaceAll(" ", "-").replace("/", "_"));

      this.$router.push("/producto/" + name).catch((err) => {});
    },
    replace(name) {
      return (name = name.replaceAll(" ", "-").replace("/", "_"));
    },
  },
};
</script>

<style scoped>
.disSlide{
  display: none;
}
#storeSection .ti-pr-cont {
  flex-direction: column !important;
}
#storeSection .pr-cont .light {
  font-family: "Rajdhani";
  padding-left: 0.2vw;
}
#storeSection .vueperslide .store-item:hover {
  transform: scale(1.025) !important;
}
.comprar-btn {
  width: 22.01vw !important;
  font-size: 0.938vw !important;
  padding: 0 8.488vw !important;
}
.comprar-btn:hover {
  padding: 0px 4.2vw !important;
  background-position-x: 11.521vw !important;
}
.cat-genre {
  border: 1px solid #fff;
  color: #fff;
}
.cat-genre a {
  color: #fff;
}

@media (max-width: 768px) {
  #storeSection .comprar-btn {
    height: 18.116vw !important;
    font-size: 3vw !important;
    padding: 0 32vw !important;
  }
  #storeSection .comprar-btn:hover {
    padding: 0 17vw !important;
    background-position-x: 48.521vw !important;
  }
  #storeSection .ti-pr-cont {
    padding: 8.696vw 1.771vw 3vw !important;
  }
}
</style>
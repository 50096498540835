var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"store-sec-cont"},[_vm._m(0),_c('div',{staticClass:"scc-slide pc"},[_c('vueper-slides',{staticClass:"no-shadow",attrs:{"visible-slides":3,"slide-multiple":"","bullets":false,"touchable":false,"breakpoints":{ 800: { visibleSlides: 2, slideMultiple: 2 } }}},_vm._l((_vm.productdata),function(slideShop,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"store-item"},[_c('a',{attrs:{"href":'/producto/' + slideShop.url}},[_c('div',{staticClass:"item-store-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                        '/get-product-image/' +
                        _vm.regexImg(slideShop.image)}}),_c('source',{attrs:{"srcset":_vm.url + '/get-product-image/' + slideShop.image,"type":"image/png"}}),_c('img',{attrs:{"src":_vm.url +
                        '/get-product-image/' +
                        _vm.regexImg(slideShop.image),"alt":"store","width":"355","height":"379"}})])])]),_c('div',{staticClass:"ti-pr-cont"},[(_vm.genreName(slideShop.categorias) !== 'No se encontraron coincidencias')?_c('div',{staticClass:"cat-genre"},[_vm._v(" CATEGORIA: "),_c('a',{attrs:{"href":_vm.genreWeb(slideShop.categorias)}},[_vm._v(_vm._s(_vm.genreName(slideShop.categorias)))])]):_c('div',{staticClass:"cat-genre"},[_vm._v(" CATEGORIA: "),_c('a',{attrs:{"href":'/categoria/' + _vm.tolc(slideShop.category_name)}},[_vm._v(_vm._s(slideShop.category_name))])]),_c('div',{staticClass:"ti-cont"},[_c('p',[_vm._v(_vm._s(slideShop.name))])]),_c('div',{staticClass:"pr-cont"},[_vm._v(" $ "+_vm._s(slideShop.price)+" "),_c('span',{staticClass:"light"},[_vm._v("MXN")])])]),_c('div',[_c('a',{staticClass:"comprar-btn",attrs:{"href":'/producto/' + slideShop.url}},[_vm._v(" COMPRAR ")])])])]},proxy:true}],null,true)})}),1)],1),_c('div',{staticClass:"scc-slide mo"},[_c('vueper-slides',{staticClass:"no-shadow",attrs:{"touchable":"","visible-slides":1,"bullets":false}},_vm._l((_vm.productdata),function(slideShop,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"store-item"},[_c('a',{attrs:{"href":'/producto/' + slideShop.url}},[_c('div',{staticClass:"item-store-cont"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url +
                        '/get-product-image/' +
                        _vm.regexImg(slideShop.image)}}),_c('source',{attrs:{"srcset":_vm.url + '/get-product-image/' + slideShop.image,"type":"image/png"}}),_c('img',{attrs:{"src":_vm.url +
                        '/get-product-image/' +
                        _vm.regexImg(slideShop.image),"alt":"store","width":"214","height":"228"}})])])]),_c('div',{staticClass:"ti-pr-cont"},[(_vm.genreName(slideShop.categorias) !== 'No se encontraron coincidencias')?_c('div',{staticClass:"cat-genre"},[_vm._v(" CATEGORIA: "),_c('a',{attrs:{"href":_vm.genreWeb(slideShop.categorias)}},[_vm._v(_vm._s(_vm.genreName(slideShop.categorias)))])]):_c('div',{staticClass:"cat-genre"},[_vm._v(" CATEGORIA: "),_c('a',{attrs:{"href":'/' + slideShop.category_name}},[_vm._v(_vm._s(slideShop.category_name))])]),_c('div',{staticClass:"ti-cont"},[_c('p',[_vm._v(_vm._s(slideShop.name))])]),_c('div',{staticClass:"pr-cont"},[_vm._v("$ "+_vm._s(slideShop.price)+" MXN")])]),_c('a',{staticClass:"comprar-btn",attrs:{"href":'/producto/' + slideShop.url}},[_vm._v(" COMPRAR ")])])]},proxy:true}],null,true)})}),1)],1),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ssc-titulo"},[_c('h2',[_vm._v("BIENVENIDO A NUESTRA")]),_c('h3',[_vm._v("TIENDA OFICIAL")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btn-ver-tienda-cont"},[_c('a',{attrs:{"href":"/tienda"}},[_c('p',{staticClass:"btn-ver-tienda"},[_vm._v("VER TODA LA TIENDA")])])])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-n-v-i"},[_c('div',{class:'titulo-u-noticias' + ' ' + _vm.isNav()},[_vm._m(0),_c('Sponsor',{staticClass:"patroc",attrs:{"secSponsor":'NOTICIAS'}})],1),_c('div',{staticClass:"last-cont"},[_vm._l((_vm.blogdatatouille.slice(0, 8)),function(lnw,i){return _c('div',{key:i,staticClass:"l-cont pc"},[_c('a',{attrs:{"href":'/blog/titulo/' + lnw.url}},[_c('div',{staticClass:"l-div"},[_c('div',{class:'l-m-content' + ' ' + _vm.isNav(),style:({
              backgroundImage:
                'url(' +
                _vm.url +
                '/get-blog-image/' +
                _vm.isWebp(_vm.regexImg(lnw.image), lnw.image) +
                ')',
            }),attrs:{"width":"513","height":"360","alt":"e"}}),_c('img',{staticClass:"red-rec",attrs:{"src":_vm.isWebp(
                require('../assets/webp/red-rec.webp'),
                require('../assets/red-rec.png')
              ),"width":"118","height":"12","alt":"rr"}})])]),_c('div',{staticClass:"text-cont"},[_c('p',{class:'date' + ' ' + _vm.isNav()},[_vm._v(" "+_vm._s(_vm.dataSortDue(lnw.date))+" ")]),_c('a',{attrs:{"href":'/blog/titulo/' + lnw.url}},[_c('h4',{staticClass:"titulo"},[_vm._v(_vm._s(lnw.title))]),_c('p',{staticClass:"parrafo"},[_vm._v(_vm._s(_vm.splitHTML(lnw.content)))])])])])}),_vm._l((_vm.blogdatatouille.slice(0, 4)),function(lnwm,i){return _c('div',{key:i,staticClass:"l-cont mo"},[_c('a',{attrs:{"href":'/blog/titulo/' + lnwm.url}},[_c('div',{staticClass:"l-div"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":_vm.url + '/get-blog-image/' + _vm.regexImg(lnwm.image)}}),_c('source',{attrs:{"srcset":_vm.url + '/get-blog-image/' + lnwm.image,"type":"image/png"}}),_c('img',{staticClass:"l-m-content",attrs:{"src":_vm.url + '/get-blog-image/' + _vm.regexImg(lnwm.image),"width":"139","height":"156","alt":"e"}})]),_vm._m(1,true)])]),_c('div',{staticClass:"text-cont"},[_c('p',{class:'date' + ' ' + _vm.isNav()},[_vm._v(" "+_vm._s(_vm.dataSortDue(lnwm.date))+" ")]),_c('a',{attrs:{"href":'/blog/titulo/' + lnwm.url}},[_c('h4',{staticClass:"titulo"},[_vm._v(_vm._s(lnwm.title))]),_c('p',{staticClass:"parrafo"},[_vm._v(_vm._s(_vm.splitHTML(lnwm.content)))])])])])})],2),_c('div',{staticClass:"lastb"},[_c('a',{attrs:{"href":"/blog"}},[_c('div',{class:'l-button' + ' ' + _vm.isNav()},[_vm._v("VER TODO")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',[_vm._v("ÚLTIMAS "),_c('span',[_vm._v("NOTICIAS")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("../assets/webp/red-rec.webp")}}),_c('source',{attrs:{"srcset":require("../assets/red-rec.png"),"type":"image/png"}}),_c('img',{staticClass:"red-rec",attrs:{"src":require("../assets/webp/red-rec.webp"),"width":"118","height":"12","alt":"rr"}})])}]

export { render, staticRenderFns }
<template>
  <transition name="modal-fade">
  <div class="modal2-backdrop enjambre" @click="close">
    <div class="modal2 gale" @click.stop="">
      <header class="modal2-header" >
        <button
          type="button"
          class="btn-close"
          @click="close"
        >
          x
        </button>
      </header>

      <section class="modal2-body-gal enj">
        <slot name="body">
          Wos!
        </slot>
       </section>

    </div>
  </div>
  </transition>
</template>


<script>
  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
<template>
  <div class="content-sh">
    <div class="banner-mode" v-for="(banner, i) in bannerAll" :key="i">
      <VideoModal v-show="isModalVisible1" @close="closeModal1">
        <template v-slot:header> {{ modalData1.title }} </template>
        <template v-slot:body> <div v-html="modalData1.url"></div> </template>
      </VideoModal>

      <GalleryModal v-show="isModalVisible2" @close="closeModal2">
        <template v-slot:header> GALERÍA </template>

        <template v-slot:body>
          <div
            class="gallery-item"
            v-for="(imadata, i) in modalData2"
            :key="imadata.id"
          >
            <img
              class="gallery-frame"
              :src="
                url +
                '/get-imagesgallery-image/' +
                isWebp(regexImg(imadata.image), imadata.image)
              "
              alt="image"
              @click="showModal3(imadata, i)"
            />
          </div>
        </template>
      </GalleryModal>

      <GalleryImgModal v-show="isModalVisible3" @close="closeModal3">
        <template v-slot:body>
          <div class="cont-img-gal">
            <div class="btn-content" @click="closeModal3">
              <a
                class="prev"
                id="pr"
                @click="plusSlides(modalData2, -1)"
                @click.stop=""
                >❮</a
              >
            </div>
            <img
              class="gallery-frame-img"
              :src="
                url +
                '/get-imagesgallery-image/' +
                isWebp(regexImg(modalData3.image), modalData3.image)
              "
              alt="image"
            />
            <div class="btn-content" @click="closeModal3">
              <a
                class="next"
                id="nx"
                @click="plusSlides(modalData2, 1)"
                @click.stop=""
                >❯</a
              >
            </div>
          </div>
          <div class="gallery-mosaic">
            <img
              v-for="(md, i) in modalData2"
              :key="md.id"
              :src="url + '/get-imagesgallery-image/' + md.image"
              alt="img"
              class="rest-of-pics"
              @click="showModal3(md, i)"
            />
          </div>
        </template>
      </GalleryImgModal>

      <div class="sliders-mode" v-if="banner.opt === 'Slider'">
        <div class="validate-slide" v-if="banner.slider.length === 1">
          <div v-for="slide in banner.slider" :key="slide.id">
            <a :href="slide.link">
              <!--  <picture>
              <source type="image/webp" srcset="https://ortopedistahermosillo.com/img/fondiu-2.b456b3c7.webp">  
              <source srcset="https://cimarronesdesonora.xyz/api/get-banner-image/GiIMgVbMDrW6watdf-nnVnXl.png" type="image/png">           
              <img width="1932" height="658" 
            src="https://cimarronesdesonora.xyz/api/get-banner-image/GiIMgVbMDrW6watdf-nnVnXl.png" 
            alt="re" id="imagewebp" class="banneh-image pece">

              </picture>-->
              <picture>
                <source
                  type="image/webp"
                  :srcset="url + '/get-banner-image/' + regexImg(slide.image)"
                />
                <source
                  :srcset="url + '/get-banner-image/' + slide.image"
                  type="image/png"
                />
                <img
                  width="1932"
                  height="658"
                  :src="url + '/get-banner-image/' + regexImg(slide.image)"
                  alt="Cimarrones de Sonora"
                  id="imagewebp"
                  class="banneh-image pece"
                />
              </picture>
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    url + '/get-banner-image/' + regexImg(slide.image_mv)
                  "
                />
                <source
                  :srcset="url + '/get-banner-image/' + slide.image_mv"
                  type="image/png"
                />
                <img
                  width="414"
                  height="707"
                  :src="url + '/get-banner-image/' + slide.image_mv"
                  alt="Cimarrones de Sonora"
                  id="imagewebp"
                  class="banneh-image mobileh"
                />
              </picture>
            </a>
          </div>
        </div>

        <div class="validate-slide" v-else>
          <VueperSlides
            autoplay
            :bullets="false"
            :arrows="false"
            :touchable="false"
            class="principal-slide"
          >
            <VueperSlide v-for="slide in banner.slider" :key="slide.id">
              <template #content>
                <a :href="slide.link">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        url + '/get-banner-image/' + regexImg(slide.image)
                      "
                    />
                    <source
                      :srcset="url + '/get-banner-image/' + slide.image"
                      type="image/png"
                    />
                    <img
                      width="1932"
                      height="658"
                      :src="url + '/get-banner-image/' + regexImg(slide.image)"
                      alt="Cimarrones de Sonora"
                      class="banneh-image"
                    /> </picture
                ></a>
              </template>
            </VueperSlide>
          </VueperSlides>

          <VueperSlides
            autoplay
            :bullets="false"
            :arrows="false"
            :touchable="false"
            class="principal-slide-mo"
          >
            <VueperSlide v-for="slide in banner.slider" :key="slide.id">
              <template #content>
                <a :href="slide.link">
                  <picture>
                    <source
                      type="image/webp"
                      :srcset="
                        url + '/get-banner-image/' + regexImg(slide.image_mv)
                      "
                    />
                    <source
                      :srcset="url + '/get-banner-image/' + slide.image_mv"
                      type="image/png"
                    />
                    <img
                      width="414"
                      height="707"
                      :src="
                        url + '/get-banner-image/' + regexImg(slide.image_mv)
                      "
                      alt="Cimarrones de Sonora"
                      class="banneh-image"
                    />
                  </picture>
                </a>
              </template>
            </VueperSlide>
          </VueperSlides>
        </div>
      </div>

      <div class="twopics-mode" v-else-if="banner.opt === 'Blogs'">
        <div
          class="noticia-ban"
          :style="{
            'background-image':
              'url(' +
              isWebp(
                url + '/get-blog-image/' + regexImg(banner.blog_izq.image),
                url + '/get-blog-image/' + banner.blog_izq.image
              ) +
              ')!important',
          }"
        >
          <a :href="'/blog/titulo/' + banner.blog_izq.url">
            <div class="cont-text">
              <p
                class="fecha"
                :style="{
                  'background-image':
                    'url(' +
                    isWebp(
                      require('../assets/reloj-white.png'),
                      require('../assets/reloj-white.png')
                    ) +
                    ')!important',
                }"
              >
                HACE {{ newsdateago(dataTransform(banner.blog_izq.date)) }}
              </p>
              <p class="new-title">{{ banner.blog_izq.title }}</p>
            </div>
          </a>
        </div>

        <div
          class="noticia-ban"
          :style="{
            'background-image':
              'url(' +
              isWebp(
                url + '/get-blog-image/' + regexImg(banner.blog_der.image),
                url + '/get-blog-image/' + banner.blog_der.image
              ) +
              ')!important',
          }"
        >
          <a :href="'/blog/titulo/' + banner.blog_der.url">
            <div class="cont-text">
              <p
                class="fecha"
                :style="{
                  'background-image':
                    'url(' +
                    isWebp(
                      require('../assets/reloj-white.png'),
                      require('../assets/reloj-white.png')
                    ) +
                    ')!important',
                }"
              >
                HACE {{ newsdateago(dataTransform(banner.blog_der.date)) }}
              </p>
              <p class="new-title">{{ banner.blog_der.title }}</p>
            </div></a
          >
        </div>
      </div>

      <div class="2pics-mode" v-else-if="banner.opt === 'Video'">
        <div class="video-play-banner" v-html="banner.video" />
      </div>
    </div>
    <!--<img :src="slide" class="" alt="">-->

    <div :class="'slide-2-cont' + ' ' + isNav()">
      <VueperSlides
        :touchable="false"
        :slide-ratio="1 / 4"
        :dragging-distance="70"
        class="no-shadow"
        :visible-slides="4"
        :bullets="false"
      >
        <VueperSlide v-for="(lnw, i) in blogdatacatouille" :key="i">
          <template #content>
            <!--

             

             
            
            
            -->
            <div v-if="lnw.typeitem == 'gallery'">
              <div class="news-content-s" @click="showModal2(lnw.imagenes)">
                <!--navigator.userAgent.toLowerCase()  <div class="hover-diag  "></div>-->
                <div
                  alt="news"
                  class="img-news"
                  :style="{
                    backgroundImage:
                      'url(' +
                      url +
                      '/get-gallery-image/' +
                      isWebp(regexImg(lnw.image), lnw.image) +
                      ')',
                  }"
                />
                <div class="news-text">
                  <p class="titulo-nov">NOVEDADES</p>
                  <p>{{ lnw.title }}</p>
                </div>
              </div>
            </div>
            <div v-if="lnw.typeitem == 'video'">
              <div class="news-content-s" @click="showModal1(lnw)">
                <!--navigator.userAgent.toLowerCase()  <div class="hover-diag  "></div>-->
                <div
                  alt="news"
                  class="img-news"
                  :style="{
                    backgroundImage:
                      'url(' +
                      url +
                      '/get-video-image/' +
                      isWebp(regexImg(lnw.image), lnw.image) +
                      ')',
                  }"
                />
                <div class="news-text">
                  <p class="titulo-nov">NOVEDADES</p>
                  <p>{{ lnw.title }}</p>
                </div>
              </div>
            </div>
            <a v-if="lnw.typeitem == 'blog'" :href="'/blog/titulo/' + lnw.url">
              <div class="news-content-s">
                <!--navigator.userAgent.toLowerCase()  <div class="hover-diag  "></div>-->
                <div
                  alt="news"
                  class="img-news"
                  :style="{
                    backgroundImage:
                      'url(' +
                      url +
                      '/get-blog-image/' +
                      isWebp(regexImg(lnw.image), lnw.image) +
                      ')',
                  }"
                />
                <div class="news-text">
                  <p class="titulo-nov">NOVEDADES</p>
                  <p>{{ lnw.title }}</p>
                </div>
              </div>
            </a>
          </template>
        </VueperSlide>
      </VueperSlides>
    </div>

    <div class="sl2-mo">
      <div
        class="nw-fist-sec"
        v-for="(lnw, i) in blogdatacatouille.slice(0, 4)"
        :key="i"
      >
        <div v-if="lnw.typeitem == 'gallery'">
          <div class="news-content-s" @click="showModal2(lnw.imagenes)">
            <picture>
              <source
                type="image/webp"
                :srcset="url + '/get-gallery-image/' + regexImg(lnw.image)"
              />
              <source
                :srcset="url + '/get-gallery-image/' + lnw.image"
                type="image/png"
              />
              <img
                width="92"
                height="92"
                :src="url + '/get-gallery-image/' + regexImg(lnw.image)"
                alt="news"
                class="img-news"
              />
            </picture>
            <div class="news-text">
              <p class="titulo-nov">NOVEDADES</p>
              <p>{{ lnw.title }}</p>
            </div>
          </div>
        </div>
        <div v-if="lnw.typeitem == 'video'">
          <div class="news-content-s" @click="showModal1(lnw.lvideo)">
            <picture>
              <source
                type="image/webp"
                :srcset="url + '/get-video-image/' + regexImg(lnw.image)"
              />
              <source
                :srcset="url + '/get-video-image/' + lnw.image"
                type="image/png"
              />
              <img
                width="92"
                height="92"
                :src="url + '/get-video-image/' + regexImg(lnw.image)"
                alt="news"
                class="img-news"
              />
            </picture>
            <div class="news-text">
              <p class="titulo-nov">NOVEDADES</p>
              <p>{{ lnw.title }}</p>
            </div>
          </div>
        </div>
        <a v-if="lnw.typeitem == 'blog'" :href="'/blog/titulo/' + lnw.url">
          <div class="news-content-s">
            <picture>
              <source
                type="image/webp"
                :srcset="url + '/get-blog-image/' + regexImg(lnw.image)"
              />
              <source
                :srcset="url + '/get-blog-image/' + lnw.image"
                type="image/png"
              />
              <img
                width="92"
                height="92"
                :src="url + '/get-blog-image/' + regexImg(lnw.image)"
                alt="news"
                class="img-news"
              />
            </picture>
            <div class="news-text">
              <p class="titulo-nov">NOVEDADES</p>
              <p>{{ lnw.title }}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import slide from "../assets/cimarrones/slide.png";

import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

import VideoModal from "../components/VideoModal.vue";
import GalleryModal from "../components/GalleryModal.vue";
import GalleryImgModal from "../components/GalleryImgModal.vue";
export default {
  components: {
    VueperSlides,
    VueperSlide,
    VideoModal,
    GalleryModal,
    GalleryImgModal,
  },
  name: "SliderHome",
  data() {
    return {
      slide: slide,
      status: "",
      msg: "",
      webpSupported: true,
      slide1: [
        {
          image: require("../assets/banner-home-first.png"),
        },
        {
          image: require("../assets/banner-home-first.png"),
        },
        {
          image: require("../assets/banner-home-first.png"),
        },
      ],
      slide1mo: [
        {
          image: require("../assets/banner-home-mo.png"),
        },
        {
          image: require("../assets/banner-home-mo.png"),
        },
        {
          image: require("../assets/banner-home-mo.png"),
        },
      ],
      slide2: [
        {
          imagen: require("../assets/news1.png"),
          link: "https://cimarronesdesonora.xyz/blog/titulo/Cimarrones%20visita%20a%20Tampico%20Madero%20para%20la%20fecha%209",
          texto: "Cimarrones gana a Venados y califica a fase final",
        },
        {
          imagen: require("../assets/news2.png"),
          link: "https://cimarronesdesonora.xyz/blog/titulo/Cimarrones%20visita%20a%20Tampico%20Madero%20para%20la%20fecha%209",
          texto: "Cimarrones gana a Venados y califica a fase final",
        },
        {
          imagen: require("../assets/news3.png"),
          link: "https://cimarronesdesonora.xyz/blog/titulo/Cimarrones%20visita%20a%20Tampico%20Madero%20para%20la%20fecha%209",
          texto: "Cimarrones gana a Venados y califica a fase final",
        },
        {
          imagen: require("../assets/news4.png"),
          link: "https://cimarronesdesonora.xyz/blog/titulo/Cimarrones%20visita%20a%20Tampico%20Madero%20para%20la%20fecha%209",
          texto: "Cimarrones gana a Venados y califica a fase final",
        },
        {
          imagen: require("../assets/news1.png"),
          link: "https://cimarronesdesonora.xyz/blog/titulo/Cimarrones%20visita%20a%20Tampico%20Madero%20para%20la%20fecha%209",
          texto: "Cimarrones gana a Venados y califica a fase final",
        },
        {
          imagen: require("../assets/news2.png"),
          link: "https://cimarronesdesonora.xyz/blog/titulo/Cimarrones%20visita%20a%20Tampico%20Madero%20para%20la%20fecha%209",
          texto: "Cimarrones gana a Venados y califica a fase final",
        },
        {
          imagen: require("../assets/news3.png"),
          link: "https://cimarronesdesonora.xyz/blog/titulo/Cimarrones%20visita%20a%20Tampico%20Madero%20para%20la%20fecha%209",
          texto: "Cimarrones gana a Venados y califica a fase final",
        },
        {
          imagen: require("../assets/news4.png"),
          link: "https://cimarronesdesonora.xyz/blog/titulo/Cimarrones%20visita%20a%20Tampico%20Madero%20para%20la%20fecha%209",
          texto: "Cimarrones gana a Venados y califica a fase final",
        },
      ],
      isModalVisible1: false,
      modalData1: null,
      isModalVisible2: false,
      modalData2: null,
      isModalVisible3: false,
      modalData3: null,
    };
  },
  created() {
    this.findAll();
    this.findAll1();
    this.findByCategory();
    this.getVideoByIdCategory();
    this.getGalleryByIdCategory();

    async () => {
      // If browser doesn't have createImageBitmap, we can't use webp.
      if (!self.createImageBitmap) {
        this.webpSupported = false;
        return;
      }

      // Base64 representation of a white point image
      const webpData =
        "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then((r) => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      this.webpSupported = await createImageBitmap(blob).then(
        () => true,
        () => false
      );
    };
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    bannerAll() {
      return this.$store.getters["banner/data"];
    },
    blogdata1() {
      return this.$store.getters["blog/data1"];
    },
    blogdatacat() {
      return this.$store.getters["blog/data2"];
    },
    blogdatacatouille() {
      let match = this.$store.getters["blog/data2"];
      let match2 = this.$store.getters["gallery/data"];
      let match3 = this.$store.getters["videos/data"];
      let array = [];
      array.push();
      array.push.apply(array,match);
      array.push.apply(array,match2);
      array.push.apply(array,match3);
      let elekipo = array.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      return elekipo;
    },
  },
  methods: {
    ...mapActions("banner", ["findAll"]),
    ...mapActions("blog", ["findAll1"]),
    ...mapActions("blog", ["getImageFile"]),
    ...mapActions("blog", ["findByCategory"]),
    ...mapActions("videos", ["getVideoByIdCategory"]),
    ...mapActions("gallery", ["getGalleryByIdCategory"]),
    ...mapActions("partidos", ["getPartidos1"]),

    showModal1(data) {
      this.isModalVisible1 = true;
      this.modalData1 = data;
    },
    closeModal1() {
      this.isModalVisible1 = false;
      this.modalData1 = "";
    },

    showModal2(data) {
      this.isModalVisible2 = true;
      this.modalData2 = data;
    },
    closeModal2() {
      this.isModalVisible2 = false;
      this.modalData2 = "";
    },

    showModal3(data) {
      this.isModalVisible3 = true;
      this.modalData3 = data;
    },
    closeModal3() {
      this.isModalVisible3 = false;
      this.modalData3 = "";
    },

    regexImg(txt) {
      let s = txt.split(".");
      return s[0] + ".webp";
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return "webp"; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return "webp"; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return "webp"; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return ""; // Safari
      }
    },

    transformImgExt(url) {
      // If webp is supported, transform the url
      if (this.webpSupported) {
        return url.replace(/\.\w{1,5}$/, ".webp");
      } else {
        // Otherwise, just return the original
        return url;
      }
    },

    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

    findAll: async function () {
      let result = await this.$store.dispatch("banner/findAll", {
        option: "banner",
      });
      this.info = result;
      return result;
    },

    findAll1: async function () {
      let result = await this.$store.dispatch("blog/findAll1", {
        option: "blog",
      });
      this.info = result;
      return result;
    },

    findByCategory: async function () {
      let result = await this.$store.dispatch("blog/findByCategory", {
        option: "blog/category",
        /*
        local id
        */
        /*id: 6,
        
        cpanel id*/
        id: 12,
        
      });
      this.info = result;
      return result;
    },

    getVideoByIdCategory: async function () {
      let result = await this.$store.dispatch("videos/getVideoByIdCategory", {
        option: "videos/category",
        /*
        local id
        */
        /*id: 6,
        
        cpanel id*/
        id: 12,
        
      });
      this.info = result;
      return result;
    },

    getGalleryByIdCategory: async function () {
      let result = await this.$store.dispatch(
        "gallery/getGalleryByIdCategory",
        {
          option: "gallery/category",
          /*
        local id
        */
        /*  id: 6,
          
        cpanel id*/
        id: 12,
        
        }
      );
      this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
  },
};
</script>

<style scoped>
/*.twopics-mode{
  display: flex;
  height: 34.271vw;
}*/
.twopics-mode {
  display: flex;
  width: 100%;
  height: fit-content;
}
.twopics-mode a {
  text-decoration-color: transparent;
}
.noticia-ban {
  width: 50%;
  height: 34.271vw;
  background-size: 106%;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  transition: 0.5s;
}
.noticia-ban:hover {
  background-size: 126%;
}
.cont-text {
  width: 100%;
  height: 34.271vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.5s;
  border-bottom: 1vw solid #000000;
  font-family: "Rajdhani";
  color: white;
  text-transform: uppercase;
  padding: 0 4.063vw;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.4782608695652174) 25%,
    rgba(255, 255, 255, 0) 67%
  );
}
.cont-text:hover {
  border-bottom: 1vw solid #9f111f;
}
.cont-text .fecha {
  font-size: 0.729vw;
  line-height: 0.99vw;
  color: #ffffff;
  background-size: 0.99vw;
  background-repeat: no-repeat;
  padding-left: 1.5vw;
}
.cont-text .new-title {
  font-size: 2.604vw;
  line-height: 109%;
}

@media (max-width: 768px) {
  .twopics-mode {
    flex-direction: column;
  }
  .noticia-ban {
    width: 100%;
    height: 62.271vw;
  }
  .cont-text {
    height: 62.271vw;
    border-bottom: 2vw solid #000000;
  }
  .cont-text:hover {
    border-bottom: 2vw solid #9f111f;
  }
  .cont-text .fecha {
    font-size: 3.729vw;
    line-height: 3.99vw;
    background-size: 3.99vw;
    padding-left: 5.5vw;
  }
  .cont-text .new-title {
    font-size: 4.604vw;
  }
}
</style>
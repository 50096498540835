<template>
  <div>
    <!-- <h1>{{ GLJornada('Jornada 2', this.partidosClub) }} - {{GVJornada('Jornada 2', this.partidosClub)}}</h1> -->
    <VueperSlides
      :arrows="true"
      :dragging-distance="100"
      :touchable="false"
      class="sl-partidos-pc"
    >
      <VueperSlide v-for="(slide, i) in jornadaProx" :key="i">
        <template #content>
          <div class="master-slide" v-if="slide.slide_tournament == 'ACTIVO'">
            <h1 class="prev-last-next-match" v-if="slide.descrp < today()">
              <a :href="'/calendario/' + slide.id">
                <div :class="'l-button' + ' ' + isNav()">VER RESULTADOS</div>
              </a>
            </h1>
            <h1
              class="prev-last-next-match"
              v-else-if="slide.descrp >= today()"
            >
              <a
                :href="boletos(slide.descrp)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div :class="'l-button' + ' ' + isNav()">COMPRAR BOLETOS</div>
              </a>
            </h1>

            <div
              class="slide-calendar versa diag"
              :style="'background-color:' + slide.color1"
              v-if="
                slide.descrp >= today() && slide.slide_tournament == 'ACTIVO'
              "
            >
              <div class="fecha-cont">
                <h1 class="fecha" :style="'color:' + slide.color3">
                  {{ matchday(dataTransform(slide.descrp)) }}
                </h1>
                <h2 class="mes">
                  {{ matchmonth(dataTransform(slide.descrp)) }}
                </h2>
                <h3 class="hora" :style="'background-color:' + slide.color2">
                  {{ slide.hr +' HRS' }}
                </h3>
              </div>

              <div class="partido-cont">
                <div class="jornada-content">
                  <div class="journey-lmx">
                    <h3 class="tipo-juego" :style="'color:' + slide.color4">
                      {{ slide.typegame }}
                    </h3>
                    <h4 class="estadio-name">Estadio: {{ slide.stadium }}</h4>
                  </div>
                  <div
                    class="canales"
                    v-if="slide.channels == 'No se encontraron coincidencias'"
                  ></div>
                  <div class="canales" v-else>
                    <picture>
                     <!--<source
                        type="image/webp"
                        :srcset="regexImg(slide.channels[0].image_channel)"
                      />
                      <source
                        :srcset="slide.channels[0].image_channel"
                        type="image/png"
                      />--> 
                      <img
                        :src="
                          url +
                          '/get-channel-image/' + slide.channels[0].image_channel
                        "
                        alt="canal"
                        class="channel"
                        width="82"
                        height="51"
                      />
                    </picture>
                  </div>
                </div>

                <div class="loc" v-if="slide.stadium == 'Héroe de Nacozari'">
                  <div class="partido-res" v-if="slide.descrp >= today()">
                    <div class="local-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="../assets/Cimarrones-mx.webp"
                          />
                          <source
                            srcset="../assets/Cimarrones-mx.png"
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            src="../assets/Cimarrones-mx.webp"
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>

                        <p class="nombre-equipo">cimarrones de sonora</p>
                      </div>
                    </div>
                    <h1
                      class="versus-h1"
                      :style="
                        'color:' +
                        slide.color5 +
                        ', border-color: ' +
                        slide.color5
                      "
                    >
                      VS
                    </h1>
                    <div class="visita-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                          />
                          <source
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            :src="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>

                        <p class="nombre-equipo">{{ slide.opname }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="partido-res" v-else-if="slide.descrp <= today()">
                    <div class="local-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="../assets/Cimarrones-mx.webp"
                          />
                          <source
                            srcset="../assets/Cimarrones-mx.png"
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            src="../assets/Cimarrones-mx.webp"
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">CIMARRONES DE SONORA</p>
                      </div>
                    </div>
                    <h1
                      class="versus-h1"
                      :style="
                        'color:' +
                        slide.color5 +
                        ', border-color: ' +
                        slide.color5
                      "
                    >
                      VS
                    </h1>
                    <div class="visita-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                          />
                          <source
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            :src="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">{{ slide.opname }}</p>
                      </div>
                      <p class="resultado">{{ slide.result_op }}</p>
                    </div>
                  </div>
                </div>

                <div class="vis" v-else>
                  <div class="partido-res" v-if="slide.descrp >= today()">
                    <div class="local-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="../assets/Cimarrones-mx.webp"
                          />
                          <source
                            srcset="../assets/Cimarrones-mx.png"
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            src="../assets/Cimarrones-mx.webp"
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">Cimarrones de Sonora</p>
                      </div>
                    </div>
                    <h1
                      class="versus-h1"
                      :style="
                        'color:' +
                        slide.color5 +
                        ', border-color: ' +
                        slide.color5
                      "
                    >
                      VS
                    </h1>
                    <div class="visita-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                          />
                          <source
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            :src="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">{{ slide.opname }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="partido-res" v-else-if="slide.descrp <= today()">
                    <div class="local-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="../assets/Cimarrones-mx.webp"
                          />
                          <source
                            srcset="../assets/Cimarrones-mx.png"
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            src="../assets/Cimarrones-mx.webp"
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">CIMARRONES DE SONORA</p>
                      </div>
                      <!-- <p class="resultado">{{slide.result_c}}</p>-->
                    </div>
                    <h1
                      class="versus-h1"
                      :style="
                        'color:' +
                        slide.color5 +
                        ', border-color: ' +
                        slide.color5
                      "
                    >
                      VS
                    </h1>
                    <div class="visita-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                          />
                          <source
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            :src="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">{{ slide.opname }}</p>
                      </div>
                      <!--<p class="resultado">{{slide.result_op}}</p>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="slide-calendar diag"
              :style="'background-color:' + slide.color1"
              v-else-if="
                slide.descrp <= today() && slide.slide_tournament == 'ACTIVO'
              "
            >
              <div class="fecha-cont">
                <h1 class="fecha" :style="'color:' + slide.color3">
                  {{ matchday(dataTransform(slide.descrp)) }}
                </h1>
                <h2 class="mes">
                  {{ matchmonth(dataTransform(slide.descrp)) }}
                </h2>
                <h3 class="hora" :style="'background-color:' + slide.color2">
                  {{ slide.hr +' HRS'/**slide.hr **/}}
                </h3>
              </div>

              <div class="partido-cont">
                <div class="jornada-content">
                  <div class="journey-lmx">
                    <h3 class="tipo-juego" :style="'color:' + slide.color4">
                      {{ slide.typegame }}
                    </h3>
                    <h4 class="estadio-name">Estadio: {{ slide.stadium }}</h4>
                  </div>
                  <div class="canales">
                    <h1></h1>
                  </div>
                </div>

                <div class="loc" v-if="slide.stadium == 'Héroe de Nacozari'">
                  <div class="partido-res" v-if="slide.descrp >= today()">
                    <div class="local-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="../assets/Cimarrones-mx.webp"
                          />
                          <source
                            srcset="../assets/Cimarrones-mx.png"
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            src="../assets/Cimarrones-mx.webp"
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">cimarrones de sonoRa</p>
                      </div>
                    </div>
                    <h1
                      class="versus-h1"
                      :style="
                        'color:' +
                        slide.color5 +
                        ', border-color: ' +
                        slide.color5
                      "
                    >
                      VS
                    </h1>
                    <div class="visita-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                          />
                          <source
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            :src="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">{{ slide.opname }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="partido-res" v-else-if="slide.descrp <= today()">
                    <div class="local-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="../assets/Cimarrones-mx.webp"
                          />
                          <source
                            srcset="../assets/Cimarrones-mx.png"
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            src="../assets/Cimarrones-mx.webp"
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">CIMARRONES DE SONORA</p>
                      </div>
                      <p class="resultado">
                        {{
                         slide.result_c
                        }}
                      </p>
                    </div>
                    <h1
                      class="versus-h1"
                      :style="
                        'color:' +
                        slide.color5 +
                        ', border-color: ' +
                        slide.color5
                      "
                    >
                      VS
                    </h1>
                    <div class="visita-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                          />
                          <source
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            :src="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">{{ slide.opname }}</p>
                      </div>
                      <p class="resultado">
                        {{
                          slide.result_op
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="vis" v-else>
                  <div class="partido-res" v-if="slide.descrp >= today()">
                    <div class="local-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="../assets/Cimarrones-mx.webp"
                          />
                          <source
                            srcset="../assets/Cimarrones-mx.png"
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            src="../assets/Cimarrones-mx.webp"
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">CIMARRONES DE SONORA</p>
                      </div>
                    </div>
                    <h1
                      class="versus-h1"
                      :style="
                        'color:' +
                        slide.color5 +
                        ', border-color: ' +
                        slide.color5
                      "
                    >
                      VS
                    </h1>
                    <div class="visita-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                          />
                          <source
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            :src="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">{{ slide.opname }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="partido-res" v-else-if="slide.descrp <= today()">
                    <div class="local-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="../assets/Cimarrones-mx.webp"
                          />
                          <source
                            srcset="../assets/Cimarrones-mx.png"
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            src="../assets/Cimarrones-mx.webp"
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">CIMARRONES DE SONORA</p>
                      </div>
                      <p class="resultado">
                        {{
                          slide.result_c
                        }}
                      </p>
                    </div>
                    <h1
                      class="versus-h1"
                      :style="
                        'color:' +
                        slide.color5 +
                        ', border-color: ' +
                        slide.color5
                      "
                    >
                      VS
                    </h1>
                    <div class="visita-cont">
                      <div class="team-cont">
                        <picture>
                          <source
                            type="image/webp"
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                          />
                          <source
                            :srcset="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            type="image/png"
                          />
                          <img
                            width="65"
                            height="72"
                            :src="
                              url +
                              '/get-calendar-image/' +
                              regexImg(slide.image)
                            "
                            alt=""
                            class="logo-equipo"
                          />
                        </picture>
                        <p class="nombre-equipo">{{ slide.opname }}</p>
                      </div>
                      <p class="resultado" >
                        {{
                          slide.result_op
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </VueperSlide>
    </VueperSlides>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import es from "dayjs/locale/es";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default {
  components: { VueperSlides, VueperSlide },
  name: "SliderPartidos",
  props: ["color1"],
  data() {
    return {
      status: "",
      msg: "",
      data: {
        isActive: true,
        hasError: false,
      },
      fechita: "",
      journey: "",
      hoje: "",
      primero: 0,
      datafachera: [{}],
      golLocal: 0,
      golVisita: 0,
    };
  },
  created() {
    this.getCalendar2();
    this.findByLigaExp();

    let payload1 = {
      idTorneo: 2,
      idDivision: 20,
      idTemporada: 74,
      idClub: 10721,
    };
    this.getPartidosClub3(payload1);
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    calendardata() {
      return this.$store.getters["calendar/data2"];
    },
    jornadaProx() {
      let arr = this.$store.getters["calendar/data4"];
      if(!Array.isArray(arr)) return []

      let newarr =[]
      for (const item of arr) {

         let getApiJornada = this.getApiJornada(this.partidosClub, item.typegame)
         if(getApiJornada.length>0  &&  getApiJornada[0]?.horaLocal){
         
          let hr = getApiJornada[0].horaLocal
          let hr2 = hr.split(':')
          item.hr = hr2[0]+':'+hr2[1]

          if(getApiJornada[0].idClubLocal ==10721){
            if(getApiJornada[0].idEstatusMinutoAMinuto !=0 ){
                item.result_c = getApiJornada[0].golLocal
                item.result_op = getApiJornada[0].golVisita
            }
          }else{
            if(getApiJornada[0].idEstatusMinutoAMinuto !=0 ){
                 item.result_c = getApiJornada[0].golVisita
                item.result_op = getApiJornada[0].golLocal
            }
          }


         }

          newarr.push({
            ...item
          })
      }

      arr = newarr

      let partidos = arr.sort((a, b) => {
        return new Date(b.descrp) - new Date(a.descrp);
      }); 
      partidos = partidos.reverse();
      dayjs.extend(customParseFormat);
      let date = dayjs().format("YYYY-MM-DD");
      let encontrado = false; 
      for (let i = 0; i < partidos.length; i++) {
        if (encontrado == false) { 
          if (i == partidos.length - 1) {
            let first_y_elements = partidos.slice(0, -1);
            let remaining_elements = partidos.slice(-1, partidos.length);
            let narr = [...remaining_elements, ...first_y_elements];
            return narr;
          } else {
            if (partidos[i].descrp > date) {
              encontrado = true; 
              let y = i % partidos.length;
              let first_y_elements = partidos.slice(0, y);
              let remaining_elements = partidos.slice(y, partidos.length);
              let narr = [...remaining_elements, ...first_y_elements];
              return narr;
            } 
          }
        }
      }
    },
    jornadaProxApi() {
      let elekipo = this.$store.getters["calendar/data5"];
      let partidos = elekipo.sort((a, b) => {
        return new Date(b.fecha) - new Date(a.fecha);
      }); 
      partidos = partidos.reverse();
      dayjs.extend(customParseFormat);
      let date = dayjs().format("YYYY-MM-DD");
      let encontrado = false; 
      for (let i = 0; i < partidos.length; i++) {
        if (encontrado == false) {
          if (partidos[partidos.length - 1]) {
            let first_y_elements = partidos.slice(0, -1);
            let remaining_elements = partidos.slice(-1, partidos.length);
            let fres = [...remaining_elements, ...first_y_elements];
            return fres;
          } else {
            if (partidos[i].descrp > date) {
              encontrado = true; 
              let y = i % partidos.length;
              let first_y_elements = partidos.slice(0, y);
              let remaining_elements = partidos.slice(y, partidos.length);
              let fres = [...remaining_elements, ...first_y_elements];
              return fres;
            }  
          }
        }
      }
    },
    color1() {
      return "background-color: black ";
    },
    partidosClub() {
      let elekipo = this.$store.getters["partidos/data5"];
      let partidos = elekipo.sort((a, b) => {
        return new Date(b.fecha) - new Date(a.fecha);
      }); 
      return partidos;
    },
  },
  methods: {
    ...mapActions("calendar", ["getCalendar2"]),
    ...mapActions("calendar", ["findByLigaExp"]),
    ...mapActions("calendar", ["getCalendarById"]),
    ...mapActions("calendar", ["getCalendarImage"]),
    ...mapActions("partidos", ["getPartidosClub3"]),
    getApiJornada(array,jornada){
      if(!Array.isArray(array)) return []
      let filt = array.filter(item => item.jornada.toLowerCase() == jornada.toLowerCase());
      return filt
    },
    hour(array,jornada){
      if(!Array.isArray(array)) return ''

      let filt = array.filter(item => item.jornada == jornada);
      
      if(filt.length==0) {
        console.log(array,jornada)
        return ''
      }
      if(!filt[0]?.horaLocal) return ''
      
      let hr = filt[0].horaLocal
      let hr2 = hr.split(':')
         
      return hr2[0]+':'+hr2[1]
    },
    boletos(fecha) {
      if (fecha == "2022-02-02") {
        find = true;
        return "https://boletomovil.com/evento/cimarrones-vs-dorados-sinaloa-2022-02-22";
      } else {
        find = true;
        return "https://boletomovil.com/cimarrones-sonora";
      }
    },
    GLJornada(jornada, torneoApi, golAdmin) {
      let spit = jornada.split(" ");
      let njor = spit[1];
      let golA = golAdmin;
      let encontrado = false;
      let gol = 0;
      /* let torneo = torneoApi[njor-1];*/
      let torneo = torneoApi;
      
      for (let i = 0; i < torneo.length; i++) {
        if (encontrado == false) {
          if (torneo[i].idEstatusMinutoAMinuto == 0) {
            gol = golA;
          }
           else if (torneo[i].jornada == jornada) {
              gol = torneo[i].golLocal;
            } else if (njor == torneo[i].idJornada) {
            encontrado = true;
            gol = torneo[i].golLocal;
          }
          else if (njor == null) {
            if (torneo[i].jornada == jornada) {
              gol = torneo[i].golLocal;
            }
          }
        }
      }
      return gol;
    },
    GVJornada(jornada, torneoApi, golAdmin) {
      let spit = jornada.split(" ");
      
      let njor = spit[1];
      let golA = golAdmin;
      let encontrado = false;
      let gol = 0;
      /* let torneo = torneoApi[njor-1];*/
      let torneo = torneoApi;
      for (let i = 0; i < torneo.length; i++) {
        if (encontrado == false) {
          if (torneo[i].idEstatusMinutoAMinuto == 0) {
            gol = golA;
          }else if (torneo[i].jornada == jornada) {
              //console.log(torneo[i]);
              gol = torneo[i].golVisita;
            } else if (torneo[i].idJornada == njor) {
            encontrado = true;
            gol = torneo[i].golVisita;
          } else if (njor == null) {
            if (torneo[i].jornada == jornada) {
              //console.log(torneo[i]);
              gol = torneo[i].golVisita;
            }
          }
        }
      }
      return gol;
    },

    regexImg(txt) {
      let s = txt.split(".");
      return s[0] + ".webp";
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return "webp"; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return "webp"; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return "webp"; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return ""; // Safari
      }
    },

    exa() {
      let xd = new Date();
      let fecha =
        xd.getFullYear() + "-" + xd.getDate() + "-" + (xd.getMonth() + 1);
      return fecha;
    },

    today() {
      var now = dayjs();
      return now.format("YYYY-MM-DD");
    },

    dataTransform: function (descrp) {
      let fecha = descrp.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    matchday: function (descrp) {
      let fecha = descrp.split("-");
      let dd = fecha[4];
      return (fecha = dd);
    },
    matchmonth: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let match = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MMM-YYYY");
      let fecha = match.split("-");
      let mm = fecha[1];
      return (fecha = mm);
    },

    date1: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MM-YYYY");
      let fecha = fa.split("-");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    date2: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MM-YYYY");
      let fecha = fa.split("-");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + dd + "-" + mm);
    },

    getCalendar2: async function () {
      let result = await this.$store.dispatch("calendar/getCalendar2", {
        option: "calendario",
      });
      //console.log(result)
      this.info = result;
      return result;
    },

    findByLigaExp: async function () {
      let result = await this.$store.dispatch("calendar/findByLigaExp", {
        option: "calendar/tournament",
        id: 8,
      });
      //console.log(result)
      this.info = result;
      return result;
    },
  },
};
</script>

<style scoped>
.master-slide {
  transition: 0.5s;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
/*.diag{
    background-image: url('../assets/diag1.png')!important;
    background-repeat: no-repeat!important;
    animation-delay: 5s!important;
    animation: efectaje 3s!important;
    animation-iteration-count: infinite!important;
    background-position-y: -18vw!important;
}

@keyframes efectaje {
  from {background-position-x: 40vw;}
  to {background-position-x: -50vw;}
}

@media (max-width:768px) {
.diag{
    background-position-y: -68vw!important;
}
@keyframes efectaje {
  from {background-position-x: 140vw;}
  to {background-position-x: -140vw;}
} 
}*/
</style>
<template>
<div class="container-n-v-i">
    <div :class="'titulo-u-noticias'+' '+isNav()">
        <h4>ÚLTIMOS <span>VIDEOS</span></h4>
        <Sponsor3 :secSponsor3="'VIDEOS'" class="patroc"/>
    </div>
    <div class="last-cont">
      <VideoModal v-show="isModalVisible4" @close="closeModal4">
        <template v-slot:header> {{ modalData4.title }} </template>
        <template v-slot:body> <div v-html="modalData4.url"></div> </template>
      </VideoModal>

            <div class="l-cont" v-for="(lvideo,i) in videodata3.slice(0,4)" :key="i" @click="showModal4(lvideo)">
                <div class="l-div">
                        <div :style="{ backgroundImage: 'url(' + url+'/get-video-image/'+  isWebp(regexImg(lvideo.image), lvideo.image)  + ')'}" 
                        width="377" height="350" alt="e" :class="'l-m-content'+' '+isNav()"/>
                        <img :src="isWebp(require('../assets/webp/red-rec.webp'), require('../assets/red-rec.png'))" width="118" height="12" alt="rr" class="red-rec">
                        <img :src="isWebp(require('../assets/webp/play1.webp'), require('../assets/play1.png'))" alt="play" class="play-video-icon"
                         width="96" height="96">
                        
                </div>
                <div class="titulo-vim">
                    <p :class="'date'+' '+isNav()">
                      {{dataSortDue(lvideo.datevideo)}}</p>
                    <div>
                        <h4 class="titulo">{{lvideo.title}}</h4>
                    </div>
                </div>
            </div>

        </div>

        <div class="lastb">
            <a href="/videos">
                <div :class="'l-button'+' '+isNav()">
                    VER TODO
                </div>
            </a>
        </div>
    </div>
    
</template>

<script>
import VueCoreVideoPlayer from 'vue-core-video-player';
import Sponsor3 from "../components/widgets/SponsorSecW3";
import VideoModal from "../components/VideoModal.vue";
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default {
  components: {
    VueCoreVideoPlayer,
    Sponsor3,
    VideoModal,
  },
  data() {
    return {
      lastvideo: [
        { 
            link:"#",
            img: require('../assets/vim1.png'), 
            fecha: 'Hace 2 días', 
            titulo:'Vallejo va por más goles ante Venados',
            prevtext:'Con un solitario gol del “Guamerucito” Brian García los Cimarrones sellaron su sexta victoria en casa del torneo y llegaron a 27 unidades,' },
        {
            link:"#",
            img: require('../assets/vim2.png'), 
            fecha: 'Hace 2 días',
            titulo:'Vallejo va por más goles ante Venados',
            prevtext:'Con un solitario gol del “Guamerucito” Brian García los Cimarrones sellaron su sexta victoria en casa del torneo y llegaron a 27 unidades,' },
        {
            link:"#",
            img: require('../assets/vim3.png'), 
            fecha: 'Hace 2 días', 
            titulo:'Vallejo va por más goles ante Venados',
            prevtext:'Con un solitario gol del “Guamerucito” Brian García los Cimarrones sellaron su sexta victoria en casa del torneo y llegaron a 27 unidades,' },
        {
            link:"#",
            img: require('../assets/vim4.png'), 
            fecha: 'Hace 2 días', 
            titulo:'Vallejo va por más goles ante Venados',
            prevtext:'Con un solitario gol del “Guamerucito” Brian García los Cimarrones sellaron su sexta victoria en casa del torneo y llegaron a 27 unidades,' },
      ],
      isModalVisible4: false,
      modalData4: null,
    }
  },
  created() {
    this.findAllHome();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    videodata3() {
      let videoda = this.$store.getters["video/data3"];
      let elekipo = videoda.sort(
        (a, b) => {return new Date(b.datevideo) - new Date(a.datevideo);}
      );
      return elekipo;
    },
  },
  methods: {
    ...mapActions("video", ["findAllHome"]),

        regexImg(txt){
          let s = txt.split('.')
          return s[0]+'.webp'

        },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return webpImg; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return webpImg; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return webpImg; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return pngImg; // Safari
            }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return 'webp'; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return 'webp'; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return 'webp'; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return ''; // Safari
            }
    },
    dataSortDue: function (date) {
     const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let newsdata = dayjs(date).locale(es).format("YYYY-MMMM-DD");
     
     let fecha = newsdata.split("-");
      let yy = fecha[0];
      let mm = fecha[1];
      let dd = fecha[2];
      return (fecha = dd  + " DE " + mm + " DEL " + yy);
    },

    showModal4(data) {
      this.isModalVisible4 = true;
      this.modalData4 = data;
    },
    closeModal4() {
      this.isModalVisible4 = false;
      this.modalData4 = '';
    },

    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

 get_youtube_thumbnail: function(url, quality){
    if(url){
        var video_id, thumbnail, result;
        if(result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))
        {
            video_id = result.pop();
        }
        else if(result = url.match(/youtu.be\/(.{11})/))
        {
            video_id = result.pop();
        }

        if(video_id){
            if(typeof quality == "undefined"){
                quality = 'high';
            }
        
            var quality_key = 'maxresdefault'; // Max quality
            if(quality == 'low'){
                quality_key = 'sddefault';
            }else if(quality == 'medium'){
                quality_key = 'mqdefault';
            } else if (quality == 'high') {
                quality_key = 'hqdefault';
            }

            var thumbnail = "http://img.youtube.com/vi/"+video_id+"/"+quality_key+".jpg";
            return thumbnail;
        }
    }
    return false;
},

    findAllHome: async function () {
      let result = await this.$store.dispatch("video/findAllHome", {
        option: "videos",
      });
      this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
}
</script>


<style scoped>
.l-m-content {
    background-size: 140%!important;
    background-position-x: center;
    transition: .5s;
}
.l-cont:hover .l-m-content {
    background-size: 180%!important;
}.patroc {
    display: none!important;
}
@media (max-width:768px) {
    .l-m-content{
        background-size: 180%!important;
    }.l-cont:hover .l-m-content {
    background-size: 220%!important;
}
}
</style>
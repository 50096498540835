<template>
    <div>
        <div class="titulo-clas">
            <h2>CLASIFICACIÓN</h2>
            <h1>LIGA EXPANSIÓN MX</h1>
            <h3>APERTURA 2020</h3>
        </div>
        <div class="tablas-ind-clas">
            <div class="tabla-individual">
                <div class="stats" v-for="(gol, i) in goleador" :key="i">
                    <img :src="gol.imagen" alt="pl" class="player">
                    <h3>GOLEADOR</h3>
                    <p>{{gol.jugador}}</p>
                    <div class="stats-count">
                        <img src="../assets/gol.png" alt="-" class="ilu">
                        <h4>{{gol.datos}}</h4>
                    </div>
                </div>
                <div class="stats" v-for="(asi, i) in asistencias" :key="i">
                    <img :src="asi.imagen" alt="pl" class="player">
                    <h3>ASISTENCIAS</h3>
                    <p>{{asi.jugador}}</p>
                    <div class="stats-count">
                        <img src="../assets/asistencia.png" alt="-" class="ilu">
                        <h4>{{asi.datos}}</h4>
                    </div>
                </div>
                <div class="stats" v-for="(inte, i) in intercepciones" :key="i">
                    <img :src="inte.imagen" alt="pl" class="player">
                    <h3>INTERCEPCIONES</h3>
                    <p>{{inte.jugador}}</p>
                    <div class="stats-count">
                        <img src="../assets/inter.png" alt="-" class="ilu">
                        <h4>{{inte.datos}}</h4>
                    </div>
                </div>
                <div class="stats" v-for="(imb, i) in imbatidas" :key="i">
                    <img :src="imb.imagen" alt="pl" class="player">
                    <h3>IMBATIDAS</h3>
                    <p>{{imb.jugador}}</p>
                    <div class="stats-count">
                        <img src="../assets/imbatidas.png" alt="-" class="ilu">
                        <h4>{{imb.datos}}</h4>
                    </div>
                </div>
            </div>

            <div class="tablalmx">
                <table class="liga-tabla">
                    <tr class="tabla-header">
                        <td> </td>
                        <td class="logo-tb-cont"> </td>
                        <td class="nombre-tb-cont"> </td>
                        <td class="puntos-cont cobre">PTS.</td>
                        <td>PJ</td>
                        <td>PG</td>
                        <td>PE</td>
                        <td>PP</td>
                        <td>GF</td>
                        <td>GC</td>
                    </tr>

                    <tr class="tabla-content" v-for="(tabla, i) in tablalmx" :key="i">
                        <td>{{tabla.pos}}</td>
                        <td class="logo-tb-cont"><img :src="tabla.img" alt="img" class="icon-team" /></td>
                        <td>{{tabla.equipo}}</td>
                        <td class="puntos-cont">{{tabla.puntos}}</td>
                        <td>{{tabla.jugados}}</td>
                        <td>{{tabla.ganados}}</td>
                        <td>{{tabla.empatados}}</td>
                        <td>{{tabla.perdidos}}</td>
                        <td>{{tabla.golesfavor}}</td>
                        <td>{{tabla.golescontra}}</td>
                    </tr>
                </table>
                <div class="btnvt">
                    <a href="#">
                        <div class="btn-ver-tabla">
                            VER TABLA COMPLETA
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            goleador: [
                { imagen: require('../assets/jug1.png'), jugador: 'JUAN GARCIA SANCHO', datos: '4' }
            ],asistencias: [
                { imagen: require('../assets/ju2.png'), jugador: 'ARTURO CARDENAS', datos: '6' }
            ],intercepciones: [
                { imagen: require('../assets/ju3.png'), jugador: 'JONATHAN VEGA', datos: '4' }
            ],imbatidas: [
                { imagen: require('../assets/ju4.png'), jugador: 'PABLO GOMEZ', datos: '2' }
            ],tablalmx: [
                { 
                    pos:'1',
                    img: require('../assets/celaya.png'), 
                    equipo: 'Celaya', 
                    puntos: '35', 
                    jugados: '15', 
                    ganados: '9', 
                    empatados: '5', 
                    perdidos: '1', 
                    golesfavor: '21', 
                    golescontra: '10'
                },
                { 
                    pos:'2',
                    img: require('../assets/celaya.png'), 
                    equipo: 'Celaya', 
                    puntos: '35', 
                    jugados: '15', 
                    ganados: '9', 
                    empatados: '5', 
                    perdidos: '1', 
                    golesfavor: '21', 
                    golescontra: '10'
                },
                { 
                    pos:'3',
                    img: require('../assets/celaya.png'), 
                    equipo: 'Celaya', 
                    puntos: '35', 
                    jugados: '15', 
                    ganados: '9', 
                    empatados: '5', 
                    perdidos: '1', 
                    golesfavor: '21', 
                    golescontra: '10'
                },
                { 
                    pos:'4',
                    img: require('../assets/celaya.png'), 
                    equipo: 'Celaya', 
                    puntos: '35', 
                    jugados: '15', 
                    ganados: '9', 
                    empatados: '5', 
                    perdidos: '1', 
                    golesfavor: '21', 
                    golescontra: '10'
                },
                { 
                    pos:'5',
                    img: require('../assets/celaya.png'), 
                    equipo: 'Celaya', 
                    puntos: '35', 
                    jugados: '15', 
                    ganados: '9', 
                    empatados: '5', 
                    perdidos: '1', 
                    golesfavor: '21', 
                    golescontra: '10'
                }
            ]
        }
    },
}
</script>
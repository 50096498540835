<template>
        <a href="https://tecate.com/" target="_blank" rel="noopener noreferrer" v-if="this.patrocinador == true">
            <img :src="getSponsor" alt="Sponsor" class="banner-sponsor" width="1597" height="194">
        </a>
        <a href="https://online.caliente.mx/page?member=cimarrones&campaign=DEFAULT&channel=DEFAULT&zone=60503210&lp=0"  target="_blank" rel="noopener noreferrer" v-else>
            <img src="../assets/calientePC.gif" alt="Sponsor" class="banner-sponsor pc" width="1604" height="68">
            <img src="../assets/calienteMO.gif" alt="Sponsor" class="banner-sponsor mo" width="1604" height="68">
        </a>
</template>

<script>
export default {
  props:{
    patrocinador: Boolean
  },
  computed: {
    getSponsor(){
      return this.patrocinador ? this.isWebp(require('../assets/webp/tecate-banner.webp'), require('../assets/tecate-banner.png')) : require('../assets/gifSponsor.gif')
    }
  },
  methods:{
    
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return webpImg; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return webpImg; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return webpImg; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return pngImg; // Safari
            }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return 'webp'; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return 'webp'; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return 'webp'; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return ''; // Safari
            }
    },

  }
  
}
</script>
<style scoped>
#sponsor a .banner-sponsor.pc{
  display: block;
}
#sponsor a .banner-sponsor.mo{
  display: none;
}
@media (max-width: 768px) {
  
#sponsor a .banner-sponsor.pc{
  display: none;
}
#sponsor a .banner-sponsor.mo{
  display: block;
}
}
</style>
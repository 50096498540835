<template>
  <div class="container-n-v-i">
    <div :class="'titulo-u-noticias' + ' ' + isNav()">
      <h4>ÚLTIMAS <span>NOTICIAS</span></h4>
      <!--<div class="patro-u-n">
                <p>PATROCINADOR OFICIAL</p>
                <img src="../assets/tkt.png" alt="sponsor">
            </div>-->
      <Sponsor :secSponsor="'NOTICIAS'" class="patroc" />
    </div>

    <div class="last-cont">
      <div
        class="l-cont pc"
        v-for="(lnw,i) in blogdatatouille.slice(0, 8)"
        :key="i"
      >
        <a :href="'/blog/titulo/' + lnw.url">
          <div class="l-div">
            <!--<div class="hover-diag"></div>-->
            <div
              :style="{
                backgroundImage:
                  'url(' +
                  url +
                  '/get-blog-image/' +
                  isWebp(regexImg(lnw.image), lnw.image) +
                  ')',
              }"
              width="513"
              height="360"
              alt="e"
              :class="'l-m-content' + ' ' + isNav()"
            />
            <img
              :src="
                isWebp(
                  require('../assets/webp/red-rec.webp'),
                  require('../assets/red-rec.png')
                )
              "
              width="118"
              height="12"
              alt="rr"
              class="red-rec"
            />
          </div>
        </a>
        <div class="text-cont">
          <p :class="'date' + ' ' + isNav()">
           {{ dataSortDue(lnw.date) }}
          </p>
          <a :href="'/blog/titulo/' + lnw.url">
            <h4 class="titulo">{{ lnw.title }}</h4>
            <p class="parrafo">{{ splitHTML(lnw.content) }}</p>
          </a>
        </div>
        <!--<p class="texto-prev" v-html="lnw.content"></p>-->
      </div>

      <div class="l-cont mo" v-for="(lnwm,i) in blogdatatouille.slice(0, 4)" 
      :key="i">
        <a :href="'/blog/titulo/' + lnwm.url">
          <div class="l-div">
            <picture>
              <source
                type="image/webp"
                :srcset="url + '/get-blog-image/' + regexImg(lnwm.image)"
              />
              <source
                :srcset="url + '/get-blog-image/' + lnwm.image"
                type="image/png"
              />
              <img
                :src="url + '/get-blog-image/' + regexImg(lnwm.image)"
                width="139"
                height="156"
                alt="e"
                class="l-m-content"
              />
            </picture>
            <picture>
              <source type="image/webp" srcset="../assets/webp/red-rec.webp" />
              <source srcset="../assets/red-rec.png" type="image/png" />
              <img
                src="../assets/webp/red-rec.webp"
                width="118"
                height="12"
                alt="rr"
                class="red-rec"
              />
            </picture>
          </div>
        </a>
        <div class="text-cont">
          <p :class="'date' + ' ' + isNav()">
            {{ dataSortDue(lnwm.date) }}
          </p>
          <a :href="'/blog/titulo/' + lnwm.url">
            <h4 class="titulo">{{ lnwm.title }}</h4>
            <p class="parrafo">{{ splitHTML(lnwm.content) }}</p>
          </a>
          <!--<p class="texto-prev" v-html="lnw.content"></p>-->
        </div>
      </div>
    </div>

    <div class="lastb">
      <a href="/blog">
        <div :class="'l-button' + ' ' + isNav()">VER TODO</div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Sponsor from "../components/widgets/SponsorSecW";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default {
  data() {
    return {};
  },
  components: {
    Sponsor,
  },
  created() {
    this.findAll();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    blogdata() {
      return this.$store.getters["blog/data"];
    },
    blogdatatouille() {
      let match = this.$store.getters["blog/data"];
      let elekipo = match.sort(
        (a, b) => {return new Date(b.date) - new Date(a.date);}
      );
      return elekipo;
    },
  },
  methods: {
    ...mapActions("blog", ["findAll"]),
    ...mapActions("blog", ["getImageFile"]),

    regexImg(txt) {
      let s = txt.split(".");
      return s[0] + ".webp";
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return "webp"; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return "webp"; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return "webp"; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return ""; // Safari
      }
    },
    dataSortDue: function (date) {
     const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let newsdata = dayjs(date).locale(es).format("YYYY-MMMM-DD");
     
     let fecha = newsdata.split("-");
      let yy = fecha[0];
      let mm = fecha[1];
      let dd = fecha[2];
      return (fecha = dd  + " DE " + mm + " DEL " + yy);
    },


    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    splitHTML(texto) {
      let regex = /(<([^>]+)>)/gi;
      let textoS = texto.replace(regex, "");
      let maxLength = 150;
      let trimmedString = textoS.substr(0, maxLength);
      trimmedString = trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      );
      return trimmedString + " ...";
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

    reverseArr(input) {
      var ret = new Array();
      for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    },

    findAll: async function () {
      let result = await this.$store.dispatch("blog/findAll", {
        option: "blog",
      });
      this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-").replaceAll("/", "_");
      return result;
    },
  },
};
</script>

<style scoped>
.l-m-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  background-size: 115% !important;
  background-position-x: center !important;
  filter: contrast(1);
  transition: 0.5s;
}
.l-cont:hover .l-m-content {
  background-position-x: center !important;
  background-size: 150% !important;
}
.patroc {
  display: none !important;
} /*.hover-diag {
    width: 19.7vw;
    height: 14.2vw;
    position: absolute;
    opacity: .6;
    z-index: 10;
    background-size: 15vw 100%;
    background-repeat: no-repeat;
    background-image: url('../assets/diag.png');
    background-position-x: 20vw;
    transition: .75s;
}.l-cont:hover .hover-diag {
    background-position-x: -15vw;
    background-color: #0f2dff9c;
}*/
</style>
